import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Head from "../components/Head"
import { IndexStyles } from "../components/styles/IndexStyles"
import CallToActionCard from "../components/CallToActionCard"
import CategorySection from "../components/CategorySection"
import StorySection from "../components/StorySection"

import Image1 from "../images/judge.jpg"
import Image2 from "../images/charger.jpg"
import Image3 from "../images/fc-image8.jpg"
import HeroHeader from "../components/HeroHeader"
import HeroImage from "../images/index-images/index-hero-banner.jpg"
import NewImageSection from "../components/NewImageSection"

const IndexPage = () => {
  return (
    <>
      <SEO title="Home" />
      <Head title="Home" />
      <Layout>
        <HeroHeader heroImage={HeroImage} />
        <IndexStyles>
          <CategorySection />
          <NewImageSection />
          <StorySection />
          <div id="call-to-action-section">
            <CallToActionCard
              title="You Be the Judge"
              buttonText="Learn More"
              image={Image1}
              link="/v2-you-be-the-judge.pdf"
            />
            <CallToActionCard
              title="Contact"
              buttonText="Contact Us"
              callToAction="Integrate your tools and teams for deeper insights and faster threat response"
              image={Image2}
              link="/contact"
            />
            <CallToActionCard
              title="Applications"
              buttonText="View Applications"
              callToAction="Integrate your tools and teams for deeper insights and faster threat response"
              image={Image3}
              link="/applications"
            />
          </div>
        </IndexStyles>
      </Layout>
    </>
  )
}

export default IndexPage
