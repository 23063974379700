import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Moment from "react-moment"

const Container = styled.div`
  max-width: 1400px;
  margin: 1rem auto;

  i {
    font-size: 3em;
    cursor: pointer;
    color: lightgrey;
    &:hover {
      color: black;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
`
const ImgContainer = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  align-items: center;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`
const ImageWrapper = styled.div`
  position: relative;
  width: 350px;
  height: 250px;
  margin: 1rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 600px) {
    width: 300px;
    height: 200px;
  }
`

const DateContainer = styled.div`
  position: absolute;
  /* width: 100%; */
  padding: 0.3rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  top: 0;
`

const ViewMoreBtn = styled.button`
  border: none;
  background-color: red;
  color: white;
  padding: 0.4rem;
  margin: 1rem;
  cursor: pointer;
`

const SelectionGroup = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;

  @media (max-width: 400px) {
    width: 350px;
  }
`

const Circle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid black;
  cursor: pointer;
`

const NewImageSection = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProductImage(
        limit: 15
        sort: { fields: [createdAt], order: DESC }
      ) {
        edges {
          node {
            date
            modelNumber
            applicationLink
            image {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)
  const [numbers, setNumbers] = useState({
    num1: 0,
    num2: 3,
  })

  const handleImageForward = () => {
    setNumbers({ num1: numbers.num1 + 3, num2: numbers.num2 + 3 })
    if (numbers.num2 > 12) {
      setNumbers({ num1: 0, num2: 3 })
    }
  }

  const handleImageBack = () => {
    setNumbers({ num1: numbers.num1 - 3, num2: numbers.num2 - 3 })
    if (numbers.num2 <= 3) {
      setNumbers({ num1: 0, num2: 3 })
    }
  }

  return (
    <>
      <Container>
        {/* <h1
          style={{ textAlign: "center", color: "#4C5155", fontSize: "3  em" }}
        >
          Image Gallery
        </h1> */}
        <ImgContainer>
          <i onClick={handleImageBack} className="fas fa-chevron-left" />
          {data.allContentfulProductImage.edges
            .slice(numbers.num1, numbers.num2)
            .map(edge => {
              return (
                <>
                  <ImageWrapper>
                    <img
                      src={edge.node.image.fluid.src}
                      alt={edge.node.modelNumber}
                    />

                    <DateContainer>
                      <Moment format="MMM Do YYYY">{edge.node.date}</Moment>
                    </DateContainer>
                  </ImageWrapper>
                </>
              )
            })}
          <i onClick={handleImageForward} className="fas fa-chevron-right" />
        </ImgContainer>
        <SelectionGroup>
          <Circle
            onClick={() => {
              setNumbers({ num1: 0, num2: 3 })
            }}
            style={{ backgroundColor: numbers.num1 === 0 ? "red" : "" }}
          />
          <Circle
            onClick={() => {
              setNumbers({ num1: 3, num2: 6 })
            }}
            style={{ backgroundColor: numbers.num1 === 3 ? "red" : "" }}
          />
          <Circle
            onClick={() => {
              setNumbers({ num1: 6, num2: 9 })
            }}
            style={{ backgroundColor: numbers.num1 === 6 ? "red" : "" }}
          />
          <Circle
            onClick={() => {
              setNumbers({ num1: 9, num2: 12 })
            }}
            style={{ backgroundColor: numbers.num1 === 9 ? "red" : "" }}
          />
          <Circle
            onClick={() => {
              setNumbers({ num1: 12, num2: 15 })
            }}
            style={{ backgroundColor: numbers.num1 === 12 ? "red" : "" }}
          />
        </SelectionGroup>
        <Link to="/images">
          <ViewMoreBtn>View All Images</ViewMoreBtn>
        </Link>
      </Container>
    </>
  )
}

export default NewImageSection
