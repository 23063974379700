import styled from "styled-components"

export const IndexStyles = styled.div`
  font-family: "Titillium Web", sans-serif;

  #call-to-action-section {
    /* height: 350px; */
    padding: 3rem 0;
    background-color: whitesmoke;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }
`
