import React from "react"
import { Link } from "gatsby"
import { CategorySectionStyles } from "./styles/CategorySectionStyles"
import Image1 from "../images/mini-hd-26d-1.png"
import Image2 from "../images/34-1.png"
import Image3 from "../images/sweeper3.png"
import MachineSelection from "./MachineSelection"

import AviationImage from "../images/index-images/aviation-index-image.jpg"
import SportsImage from "../images/index-images/sports-index-image.jpg"
import EducationImage from "../images/index-images/education-index-image.jpg"
import ManufacturingImage from "../images/index-images/manufacturing-index-image.jpg"
import RetailImage from "../images/index-images/retail-index-image.jpg"

import MiniHDImage from "../images/index-images/mini-hd-index-image.png"
import Image34 from "../images/index-images/34-index-image.png"
import PilotImage from "../images/index-images/pilot-index-image.png"
import TrImage from "../images/index-images/tr-index-image.png"
import GtxImage from "../images/index-images/gtx-index-image.png"
import XRImage from "../images/index-images/xr-46c-1.png"

const CategorySection = () => {
  return (
    <CategorySectionStyles>
      <div className="parallax">
        {/* <h1 style={{ fontSize: "1.7em" }}>
          Outstanding<span> QUALITY</span> and
          <span> DURABILITY</span>
        </h1> */}
        <div className="machine-container">
          <MachineSelection
            title="Walk Behind Floor Scrubbers"
            machineImage={MiniHDImage}
            applicationImage={AviationImage}
            link="/walk-behind-floor-scrubbers"
          />
          <MachineSelection
            title="Walk Behind Floor Sweepers"
            applicationImage={SportsImage}
            machineImage={Image34}
            link="/walk-behind-floor-sweepers"
          />
          <MachineSelection
            title="Ride On Floor Scrubbers"
            machineImage={PilotImage}
            applicationImage={EducationImage}
            link="/ride-on-floor-scrubbers"
          />
          <MachineSelection
            title="Ride On Floor Sweepers"
            machineImage={TrImage}
            applicationImage={ManufacturingImage}
            link="/ride-on-sweepers"
          />
          <MachineSelection
            title="Sweeper Scrubbers"
            machineImage={XRImage}
            applicationImage={RetailImage}
            link="/ride-on-floor-scrubbers/xr"
          />
        </div>
      </div>
    </CategorySectionStyles>
  )
}

export default CategorySection
