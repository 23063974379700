import React from "react"
import styled from "styled-components"
import AppImage from "../images/aviation.jpg"
import MachineImage from "../components/individualProductPage/images/mini-hd-25c_0001_mini-hd-25c-1.6.png"
import { Link } from "gatsby"

const Container = styled.div`
  width: 250px;
  height: 250px;
  /* border: 2px solid black; */
`
const ImgContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border: 4px solid black;
  margin: 0.5rem;

  #bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #machine-img {
    width: 170px;
    position: absolute;
    right: -80px;
    bottom: -60px;
  }

  h4 {
    position: absolute;
    /* bottom: -40; */
    top: 0;
    margin: 0;
    color: white;

    background-color: rgba(0, 0, 0, 0.4);
    width: 220px;
    text-align: center;
  }
`

const MachineSelection = props => {
  return (
    <Link to={props.link}>
      <Container>
        <ImgContainer>
          <img id="bg-image" src={props.applicationImage} alt="" />
          <img id="machine-img" src={props.machineImage} alt="" />
          <h4>{props.title}</h4>
        </ImgContainer>
      </Container>
    </Link>
  )
}

export default MachineSelection
